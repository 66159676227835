export default {
  'canalplus.block.advertising': require('./advertising'),
  'canalplus.block.alternating_presentation': require('./alternating-presentation'),
  'canalplus.block.apash.reactivation_before_end': require('./selfcare/apash/reactivation-before-end'),
  'canalplus.block.apash.termination_confirmation': require('./selfcare/apash/termination-confirmation'),
  'canalplus.block.apash.update_payment_method': require('./selfcare/apash/update-payment'),
  'canalplus.block.articles.article': require('./articles/article'),
  'canalplus.block.articles.list': require('./articles/list'),
  'canalplus.block.back_link': require('./back-link'),
  'canalplus.block.background_video': require('./background-video'),
  'canalplus.block.banner': require('./banner'),
  'canalplus.block.basic_tiles': require('./basic-tiles'),
  'canalplus.block.button': require('./button'),
  'canalplus.block.column.edito': require('./column-edito'),
  'canalplus.block.contact': require('./contact'),
  'canalplus.block.cookie_consent_button': require('./cookie-consent/button'),
  'canalplus.block.cookie_trckingtool': require('./cookie-consent/banner'),
  'canalplus.block.due_date': require('./due-date'),
  'canalplus.block.dynamic.button': require('./dynamic/button'),
  'canalplus.block.dynamic.legal_notices': require('./dynamic/legal-notice'),
  'canalplus.block.dynamic.offer.back_link': require('./dynamic/offer/back-link'),
  'canalplus.block.dynamic.offer.banner': require('./banner'),
  'canalplus.block.dynamic.offer.channels': require('./dynamic/offer/channels'),
  'canalplus.block.dynamic.offer.decoders': require('./dynamic/offer/decoders'),
  'canalplus.block.dynamic.offer.header': require('./dynamic/offer/sub-header'),
  'canalplus.block.dynamic.offer.legal_notices': require('./dynamic/legal-notice'),
  'canalplus.block.dynamic.offer.premium': require('./premium'),
  'canalplus.block.dynamic.program': require('./dynamic/program'),
  'canalplus.block.edito.tabs': require('./edito/tabs'),
  'canalplus.block.faq_questions': require('./faq/question-list'),
  'canalplus.block.faq': require('./faq/qanda'),
  'canalplus.block.footer': require('./footer'),
  'canalplus.block.form': require('./form'),
  'canalplus.block.form.confirmation': require('./form-confirmation'),
  'canalplus.block.form.submission': require('./form-submission'),
  'canalplus.block.geolocation': require('./modal-geolocation'),
  'canalplus.block.header': require('./header'),
  'canalplus.block.hodor_vod': require('./vod/hodor'),
  'canalplus.block.iframe': require('./iframe'),
  'canalplus.block.item_grid': require('./item-grid'),
  'canalplus.block.javascript.html': require('./html'),
  'canalplus.block.javascript': require('./javascript'),
  'canalplus.block.jsonld': require('./json-ld'),
  'canalplus.block.legacy_vod.catalog': require('./vod/legacy/catalog'),
  'canalplus.block.legacy_vod.category': require('./vod/legacy/catalog'),
  'canalplus.block.legacy_vod.content_pack_automatic': require('./program-pack/vod-legacy'),
  'canalplus.block.legacy_vod.detail': require('./vod/legacy/detail'),
  'canalplus.block.legacy_vod.folder': require('./vod/legacy/folder'),
  'canalplus.block.legal_notice': require('./dynamic/legal-notice'),
  'canalplus.block.live_player': require('./live-player'),
  'canalplus.block.loader': require('./loader'),
  'canalplus.block.map': require('./map'),
  'canalplus.block.maverick.contact': require('./one-shop/contact'),
  'canalplus.block.maverick.jaquettes': require('./one-shop/jaquettes'),
  'canalplus.block.maverick.services': require('./one-shop/services'),
  'canalplus.block.maverick.tiles': require('./one-shop/tiles'),
  'canalplus.block.maverick.jaquettes.tabs': require('./one-shop/jaquettes-tabs'),
  'canalplus.block.mea.automatic_slider': require('./mea/automatic-slider'),
  'canalplus.block.mea.cover': require('./mea/cover'),
  'canalplus.block.mea.hero_banner': require('./mea/hero-banner'),
  'canalplus.block.mea.intermediate': require('./mea/intermediate'),
  'canalplus.block.mea.manual_slider': require('./mea/manual-slider'),
  'canalplus.block.mea.poster': require('./mea/poster'),
  'canalplus.block.mea': require('./mea/mea'),
  'canalplus.block.offer.channels': require('./offer/channels'),
  'canalplus.block.offer.digital.advantages': require('./digital-offer/advantages'),
  'canalplus.block.offer.eshop': require('./eshop/offers'),
  'canalplus.block.offer.exposed': require('./offers'),
  'canalplus.block.offer.subscription_offer_form': require('./eshop/offer-form'),
  'canalplus.block.offer.subscription_offer_summary': require('./eshop/summary'),
  'canalplus.block.oneshop.offer.subscription_offer_summary': require('./one-shop/subscription-offer-summary'),
  'canalplus.block.oneshop.products.highlight': require('./one-shop/offer-highlight'),
  'canalplus.block.oneshop.products.cards': require('./one-shop/offers-cards'),
  'canalplus.block.oneshop.propal.details': require('./one-shop/propal-details'),
  'canalplus.block.oneshop.propal.selection': require('./one-shop/propal-selection'),
  'canalplus.block.parent_code.reset': require('./selfcare/parent-code/reset'),
  'canalplus.block.parent_code.save': require('./selfcare/parent-code/save'),
  'canalplus.block.selfcare.partner_services': require('./selfcare/partner-services'),
  'canalplus.block.password_reset.step.update_password': require('./reset-password/email-update'),
  'canalplus.block.password_reset.step.validation_email': require('./reset-password/email-validation'),
  'canalplus.block.payment_button_myanmar': require('./payment-button-myanmar'),
  'canalplus.block.pre_registration.update_password': require('./pre-registration/update-password'),
  'canalplus.block.presentation': require('./presentation'),
  'canalplus.block.presentations_M_pack': require('./presentations-m-pack'),
  'canalplus.block.program_calendar': require('./program-calendar'),
  'canalplus.block.program_mea_categories': require('./program-mea/categories'),
  'canalplus.block.program_mea': require('./program-mea/default'),
  'canalplus.block.referral': require('./referral'),
  'canalplus.block.register.step.confirmation': require('./confirmation'),
  'canalplus.block.register': require('./register'),
  'canalplus.block.selfcare.account_data': require('./selfcare/account-data'),
  'canalplus.block.selfcare.apash.checkout': require('./selfcare/apash/checkout'),
  'canalplus.block.selfcare.apash.client_exists': require('./selfcare/apash/already-client'),
  'canalplus.block.selfcare.apash.end_contract_reason': require('./selfcare/apash/cancellation'),
  'canalplus.block.selfcare.apash.end_contract': require('./selfcare/apash/cancel-contract'),
  'canalplus.block.selfcare.apash.manage_subscription': require('./selfcare/apash/manage-subscription'),
  'canalplus.block.selfcare.apash.one_auth': require('./selfcare/apash/one-auth'),
  'canalplus.block.selfcare.apash.reactivation': require('./selfcare/apash/reactivation'),
  'canalplus.block.selfcare.apash.referral': require('./selfcare/apash/referral'),
  'canalplus.block.selfcare.category_v_access': require('./selfcare/category-v-access'),
  'canalplus.block.selfcare.contact': require('./selfcare/contact-infos'),
  'canalplus.block.selfcare.contract_list': require('./selfcare/contract-list'),
  'canalplus.block.selfcare.data_consumption': require('./selfcare/data-consumption'),
  'canalplus.block.selfcare.digital_vault': require('./selfcare/digital-vault'),
  'canalplus.block.selfcare.disney_service': require('./selfcare/disney-service'),
  'canalplus.block.selfcare.equipments': require('./selfcare/equipments'),
  'canalplus.block.selfcare.event_booking': require('./selfcare/event-booking'),
  'canalplus.block.selfcare.event_list': require('./selfcare/event-list'),
  'canalplus.block.selfcare.gifts': require('./selfcare/gifts'),
  'canalplus.block.selfcare.image_reactivation': require('./selfcare/image-reactivation'),
  'canalplus.block.selfcare.out_package': require('./selfcare/out-package'),
  'canalplus.block.selfcare.pack': require('./selfcare/pack'),
  'canalplus.block.selfcare.parental_code_reset': require('./selfcare/parental-code-reset'), // DEPRECATED
  'canalplus.block.selfcare.payment_history': require('./selfcare/payment-history'),
  'canalplus.block.selfcare.payment_systempay': require('./selfcare/payment-systempay'),
  'canalplus.block.selfcare.payment': require('./selfcare/payment'),
  'canalplus.block.selfcare.spotlight_subscription_options_list': require('./selfcare/subscription-options/spotlight'),
  'canalplus.block.selfcare.subscription_end_process': require('./selfcare/subscription-tunnel/subscription-end-process'),
  'canalplus.block.selfcare.subscription_options_list': require('./selfcare/subscription-options/list'),
  'canalplus.block.selfcare.subscription_options_summary': require('./selfcare/subscription-options/summary'),
  'canalplus.block.selfcare.subscription_payment_method': require('./selfcare/subscription-payment-method'),
  'canalplus.block.selfcare.subscription_renewal': require('./selfcare/subscription-renewal'),
  'canalplus.block.selfcare.subscription_tunnel': require('./selfcare/subscription-tunnel/funnel'),
  'canalplus.block.selfcare.welcome': require('./selfcare/welcome'),
  'canalplus.block.selfcare2.offers': require('./selfcare/offers'),
  'canalplus.block.selfcare2.unpaid_alerts': require('./selfcare/unpaid-alert'),
  'canalplus.block.selfcare2.welcome': require('./selfcare/welcome-two'),
  'canalplus.block.shops.map': require('./shops/map'),
  'canalplus.block.shops.search': require('./shops/search'),
  'canalplus.block.slider_comparator': require('./comparator'),
  'canalplus.block.smartfaq': require('./smart-faq'),
  'canalplus.block.tabs': require('./tabs'),
  'canalplus.block.text': require('./text'),
  'canalplus.block.title': require('./title'),
  'canalplus.block.tracking.google_analytics.header': require('./tracking/google-analytics'),
  'canalplus.block.tv_guide': require('./tv-guide'),
  'canalplus.block.unified.menu': require('./menu'),
  'canalplus.block.validationform': require('./validation-form'),
  'canalplus.block.video.automatic_playlist': require('./video-playlist'),
  'canalplus.block.video.player': require('./player'),
  'canalplus.block.video': require('./video'),
  // TODO: Does this still exists ?
  // 'canalplus.block.dynamic.offer.button': require('./dynamic/offer/button'),

  header: {
    Component: 'div'
  },
  content: {
    Component: 'div'
  },
  footer: {
    Component: 'div'
  }
};
