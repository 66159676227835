import React from 'react';

import {
  BlockWrapper,
  SliderContainer,
  Container,
  Image,
  TextContainer,
  ContentWrapper,
  PictureWrapper,
  PictureShadow,
  Description,
  StyledLink,
  StyledButton
} from '../styles/components';
import Button from 'shared/components/presentational/Button/Button';
import Slider from 'shared/components/presentational/Slider/Slider';
import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import { AnimationFormattedProps } from '../types';
import Title from 'shared/components/presentational/Title';
import { Sticker } from 'shared/blocks/one-shop/shared/components/Sticker/component/Sticker';

export default function AnimatedHeroBanner(props: AnimationFormattedProps) {
  const { items, autoPlaySpeed, bannerTemplate } = props;

  const renderHeroBanner = React.useCallback(
    (item: AnimationFormattedProps['items'][0], index: number) => {
      return (
        <SliderContainer key={index}>
          <ContentWrapper>
            <TextContainer>
              {item.sticker && <Sticker label={item.sticker} />}
              {item.title && (
                <Title
                  titleLabel={item.title}
                  titleHtmlTag={item.titleHtmlTag}
                />
              )}
              <Description>{item.description}</Description>
              {item.link && (
                <StyledLink variant="tertiary" to={item.link.url}>
                  <Button.children.Text>{item.link.label}</Button.children.Text>
                </StyledLink>
              )}
            </TextContainer>
            {item.button.label && (
              <StyledButton variant="primary" to={item.button.url}>
                <Button.children.Text>{item.button.label}</Button.children.Text>
              </StyledButton>
            )}
          </ContentWrapper>

          <PictureWrapper>
            <PictureShadow></PictureShadow>
            <Image
              responsivesUrls={item.banner.pictures}
              template={bannerTemplate}
            />
          </PictureWrapper>
        </SliderContainer>
      );
    },
    [bannerTemplate]
  );

  return (
    <BlockWrapper>
      {items.length > 1 ? (
        <Slider
          showDots={true}
          isCenterMode={false}
          isInfinite={true}
          initialSlide={0}
          prevArrow={<></>}
          slidesToShow={1}
          autoPlaySpeed={autoPlaySpeed}
          isOneShop={true}
          nextArrow={
            <OneShopArrow
              name="ArrowLeftOneShop"
              isTransparent={true}
              isSecondaryDark={true}
            />
          }
        >
          {items.map(renderHeroBanner)}
        </Slider>
      ) : (
        <Container>{items.map(renderHeroBanner)}</Container>
      )}
    </BlockWrapper>
  );
}
