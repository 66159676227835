import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  SlideWrapper,
  SlideHeading,
  SlideThumbnail
} from 'shared/components/presentational/Slider/Slide/styles/components';
import {
  SlideTitle,
  SlideSubtitle
} from 'shared/blocks/mea/shared/styles/components';
import { TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP } from 'shared/components/presentational/Slider/styles/constants';
import { IconWrapper, ArticleIcon, InsetSpan } from '../styles/components';

export interface Props {
  className?: string;
  style?: object;
  title: string | null;
  subtitle: string | null;
  imageUrl: string;
  alt?: string;
  slug: string;
  onClick?: () => void;
  onHover?: () => void;
}

const ConditionalLink = ({
  href,
  children,
  onClick
}: {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  if (!onClick) {
    return <>{children}</>;
  }
  return (
    <Link
      to={href}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      <InsetSpan />
      {children}
    </Link>
  );
};

export default class ArticleSlide extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      title,
      subtitle,
      imageUrl,
      alt,
      className,
      style,
      onClick,
      onHover,
      slug
    } = this.props;

    return (
      <SlideWrapper className={className} style={style} onMouseEnter={onHover}>
        <SlideThumbnail
          alt={alt}
          url={imageUrl}
          height={TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}
        />
        <SlideHeading>
          <SlideTitle>
            <ConditionalLink href={`/articles/${slug}`} onClick={onClick}>
              {title}
            </ConditionalLink>
          </SlideTitle>
          <SlideSubtitle>{subtitle}</SlideSubtitle>
        </SlideHeading>
        <IconWrapper>
          <ArticleIcon />
        </IconWrapper>
      </SlideWrapper>
    );
  }
}
