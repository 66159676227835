import React from 'react';

import { Button } from '../../../shared/components/Button/Button';
import { FormattedProduct } from '../../types';
import { Groups } from '../Groups';
import OfferDetailsRoc from '../../../shared/containers/OfferDetailsRocContainer';
import {
  Wrapper,
  TitleWrapper,
  Sticker,
  SlideWrapper
} from './styles/components';
import Title from 'shared/components/presentational/Title';

export const Card = ({
  promotionalSticker,
  title,
  offerDetails,
  primaryButton,
  secondaryButton,
  groups,
  moreChannelsIcon,
  isSliderHidden,
  slidesToShow,
  ...props
}: FormattedProduct & { isSliderHidden: boolean; slidesToShow: number }) => {
  return (
    <SlideWrapper slidesToShow={slidesToShow} {...props}>
      <Wrapper isSliderHidden={isSliderHidden}>
        <TitleWrapper hideSticker={promotionalSticker == null}>
          <Sticker label={promotionalSticker} />
          <Title titleLabel={title} titleHtmlTag="h2"></Title>
        </TitleWrapper>
        <OfferDetailsRoc {...offerDetails} />
        {primaryButton ? (
          <Button
            {...primaryButton}
            variant="primary"
            fullWidthMobile
            fullWidthDesktop
          />
        ) : null}
        {secondaryButton ? (
          <Button
            {...secondaryButton}
            variant="secondary"
            fullWidthMobile
            fullWidthDesktop
          />
        ) : null}
        <Groups groups={groups} moreChannelsIcon={moreChannelsIcon} />
      </Wrapper>
    </SlideWrapper>
  );
};
