import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { Sticker as SickerComponent } from 'shared/blocks/one-shop/shared/components/Sticker/component/Sticker';

const CARD_MIN_WIDHT_MOBILE = 280;
const CARD_MAX_WIDHT_DESKTOP = 520;

export const TitleWrapper = styled.div<{ hideSticker: boolean }>`
  display: flex;
  flex-direction: ${({ hideSticker }) =>
    hideSticker ? 'column-reverse' : 'column'};
  gap: var(--spacing-xl);
`;

export const Sticker = styled(SickerComponent)<{ label: string | null }>`
  display: ${({ label }) => (label == null ? 'none' : 'flex')};

  ${mediaQueries.fromTablet<{ label: string | null }>`
    display: flex;
    visibility: ${({ label }) => (label == null ? 'hidden' : '')};
  `}
`;

export const Wrapper = styled.div`
  display: flex !important;
  flex: auto;
  flex-direction: column;
  gap: var(--spacing-xl);
  background-color: var(--surface-primary);
  padding: var(--spacing-xl) var(--spacing-m);
  border-radius: 3px;
  width: ${CARD_MIN_WIDHT_MOBILE}px;

  ${mediaQueries.fromTablet<{ isSliderHidden: boolean }>`
min-width: ${props =>
    props.isSliderHidden ? `${CARD_MAX_WIDHT_DESKTOP}px` : 'unset'}
    padding: var(--spacing-xl);
  `}

  ${mediaQueries.fromHd`
min-width: unset
  `}
`;

export const SlideWrapper = styled.div`
  display: flex !important;
  padding: 0 var(--spacing-xs);
  ${mediaQueries.fromHd<{ slidesToShow: number }>`
  width: ${props =>
    `calc((100vw - var(--container-minimum-padding-tablet) * 2 ) / ${props.slidesToShow})`};
  `}
`;
